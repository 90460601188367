// Importing React and external libs
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Importing antd
import { Tooltip } from 'antd';
import 'antd/es/tooltip/style/css';

// Importing Helix hooks & components
import KmapIcon from 'helix/ui/kmapIcon';
import useLanguage from 'helix-hooks/language';

import './icon.scss';

const DISEASE_STATUS_MAP = {
  'hemizygous_affected': 'affected',
  'hemizygous_affected_maternal': 'affected',
  'hemizygous_normal': 'unaffected',
  'heterozygous_affected': 'affected',
  'heterozygous_affected_maternal': 'affected',
  'heterozygous_affected_paternal': 'affected',
  'heterozygous_carrier': 'carrier',
  'heterozygous_carrier_maternal': 'carrier',
  'heterozygous_carrier_paternal': 'carrier',
  'heterozygous': 'unaffected',
  'homozygous_affected': 'affected',
  'homozygous_normal': 'unaffected',
  'unaffected': 'unaffected',
  'unknown': 'no_result',
};

const DISEASE_STATUS_PRIORITY = {
  'affected': 4,
  'carrier': 3,
  'unaffected': 2,
  'no_result': 1,
};

const AnalysisFailedIcon = ({ size = 25 }) => (
  <svg className='analysis-failed' height={size} width={size} viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
    <circle cx="250" cy="250" r="222" />
    <line x1="0" y1="500" x2="500" y2="0" />
  </svg>
);

const SampleIcon = ({ biomaterial, tooltip = true, isLink = true }) => {
  const Language = useLanguage();
  const {
    analysis_id: analysisId,
    analysis_status: analysisStatus,
    bp_analysis_id: bpAnalysisId,
    biomaterial_designation: designation,
    biomaterial_name: name,
    biomaterial_sex: rawSex,
    case_id: caseId,
    // cn_status: rawStatus,
    disease_status: rawDisease,
    id,
    gene_regions: geneRegions,
    qc,
  } = biomaterial;

  // TODO: Remove this temporary hardcoding of cn_status
  // const status = (rawStatus || 'waiting').toLowerCase();
  const status = rawDisease ? 'euploid' : 'waiting';

  const statusDiseaseMap = {
    'waiting': 'waiting',
    'no_result': 'no_result',
    'euploid': rawDisease,
    'aneuploid': rawDisease,
    'segmental': rawDisease
  };
  const sex = designation ? rawSex : 'u';
  const analysisLink = `/case/${caseId}/analysis/${analysisId}`;

  let disease = (statusDiseaseMap[status] ?? '').toLowerCase();

  // For trio we use status defined by user in accessioning
  if (designation) {
    const diseaseStatuses = geneRegions.map(region => DISEASE_STATUS_MAP[region.status ?? 'unknown']);
    // If multiple statues are found we use the most severe status for the icon
    const sortedStatuses = diseaseStatuses.sort((a, b) => DISEASE_STATUS_PRIORITY[b] - DISEASE_STATUS_PRIORITY[a]);
    disease = sortedStatuses[0] ?? 'no_result';
  }

  const backgroundColor = qc === 'FAIL' ? '#c1c1c1' : null;
  const size = sex !== 'u' ? 30 : 25;

  const isTrioAnalysisFailed = analysisStatus === 'failed_trio';
  const isEmbryoAnalysisFailed = analysisStatus === 'failed_embryos';
  const isTrio = ['father', 'mother', 'reference'].includes(designation);
  const isAnalysisBiomaterialFailed = (isTrioAnalysisFailed && isTrio) || (isEmbryoAnalysisFailed && !rawDisease && bpAnalysisId);

  const icon = isAnalysisBiomaterialFailed
    ? <AnalysisFailedIcon />
    : <KmapIcon height={size} width={size} disease={disease} cnStatus={status} sex={sex} />;

  return (
    <Tooltip title={tooltip && `${name} - ${Language.get('gene', isAnalysisBiomaterialFailed ? 'FAILED' : disease)}`}>
      {isLink ? (
        <Link
          style={{ backgroundColor, lineHeight: 1, display: 'inline-block' }}
          to={`${analysisLink}${designation ? '' : `/sample/${id}`}`}
        >
          {icon}
        </Link>
      ) : <span style={{ display: 'inline-block', backgroundColor, lineHeight: 1 }}>{icon}</span>}
    </Tooltip>
  );
};

SampleIcon.propTypes = {
  tooltip: PropTypes.bool,
};

SampleIcon.propDefaults = {
  tooltip: true,
};

export { SampleIcon as Icon };
