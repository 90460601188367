// Importing react libs
import React from 'react';

// Importing react libs
import { Table, Typography } from 'antd';
import 'antd/es/table/style/css';
import 'antd/es/typography/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app component
import AppAPI from 'modules/api';
import { Icon } from 'components/biomaterial/icon';

// Importing component style
import './summary.scss';

// Defining constants
const { Title } = Typography;

// Exporting component
export const Summary = ({ biomaterials }) => {
  const Language = useLanguage();
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');

  const columns = [{
    dataIndex: 'biomaterial_name',
    title: Language.get('case', 'SAMPLE_ID'),
  }, {
    dataIndex: 'biomaterial_cycle_id',
    title: Language.get('case', 'CYCLE_ID'),
  }, {
    dataIndex: 'biomaterial_barcode',
    title: Language.get('report', 'BARCODE'),
  }, {
    dataIndex: 'beadchip_barcode',
    title: Language.get('report', 'BEADCHIP_BARCODE'),
  }, {
    dataIndex: 'biomaterial_subarray',
    title: Language.get('case', 'SUBARRAY'),
  }, {
    dataIndex: 'biomaterial_bio_type',
    title: Language.get('case', 'SAMPLE_TYPE'),
    render: (text) => (text && Language.get('biomaterial', text)) || '',
  }, {
    dataIndex: ['stats', 'regions'],
    title: Language.get('case', 'PREDICTED_STATUS'),
    render: (regions) => Array.isArray(regions) ? regions.map(({ name, predictedStatus }) => (
      <div key={name}>{name}: {predictedStatus ? Language.get('gene', predictedStatus) : ''}</div>
    )) : null,
  }, ...(isCnvEnabled ? [{
    dataIndex: ['stats', 'aneuploidyStatus'],
    title: Language.get('report', 'ANEUPLOIDY_STATUS'),
  }] : []), {
    dataIndex: ['stats', 'qc'],
    title: Language.get('case', 'QC'),
  }, {
    key: 'result',
    title: Language.get('case', 'RESULT'),
    render: (biomaterial) => biomaterial && <Icon biomaterial={biomaterial} isLink={false} />
  }];

  return (
    <div className="sample-details">
      <Title level={3}>{Language.get('case', 'SAMPLE_DETAILS')}</Title>
      <Table
        bordered
        columns={columns}
        dataSource={biomaterials}
        pagination={false}
        rowKey="biomaterial_id"
      />
    </div>
  );
};
