// Importing React and external libs
import React from 'react';

// Importing app components
import AppAuthenticator from './appAuthenticator';
import EmailAuthenticator from './emailAuthenticator';

// Importing CSS
import './authenticator.scss';


const MFAAuthenticator = ({ highlighted = false }) => {
  const {
    user: {
      organization: {
        setting: {
          MISCELLANEOUS: { ENABLE_MFA_FOR_ALL = false } = {}
        } = {}
      } = {},
    } = {}
  } = window.app ?? {};

  if (!ENABLE_MFA_FOR_ALL) {
    return null;
  }

  return (
    <div className={`mfa ${highlighted ? 'highlighted' : ''}`}>
      <AppAuthenticator />
      <EmailAuthenticator />
    </div>
  );
};

export default MFAAuthenticator;
