// Importing react libs
import React from 'react';

// Importing antd libs
import { Table } from 'antd';
import 'antd/es/table/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing app components & modules
import AppAPI from 'modules/api';
import { Icon } from 'components/biomaterial';

export const Meta = ({ biomaterial }) => {
  const Language = useLanguage();
  const isCnvEnabled = AppAPI.Organization.getHostSetting('enable_cnv');

  const dataSource = [{
    key: '1',
    ...(biomaterial?.stats ?? {}),
    status: biomaterial && <Icon biomaterial={biomaterial} tooltip={false} />,
  }];

  const columns = [{
    dataIndex: 'callRate',
    key: 'callRate',
    title: Language.get('sample', 'CALL_RATE'),
  }, {
    dataIndex: 'aaRate',
    key: 'aa',
    title: Language.get('sample', 'AA'),
  }, {
    dataIndex: 'abRate',
    key: 'ab',
    title: Language.get('sample', 'AB'),
  }, {
    dataIndex: 'bbRate',
    key: 'bb',
    title: Language.get('sample', 'BB'),
  }, {
    dataIndex: 'adoRate',
    key: 'ado',
    title: Language.get('sample', 'ADO'),
  }, {
    dataIndex: 'misCallRate',
    key: 'misCall',
    title: Language.get('sample', 'MIS_CALL'),
  }, {
    dataIndex: 'xHetRate',
    key: 'xHetRate',
    title: Language.get('sample', 'X_HETEROZYGOUS_RATE'),
  }, {
    dataIndex: 'yCallRate',
    key: 'yCallRate',
    title: Language.get('sample', 'Y_CALL_RATE'),
  }, ...(isCnvEnabled ? [{
    dataIndex: 'aneuploidyStatus',
    key: 'aneuploidyStatus',
    title: Language.get('sample', 'ANEUPLOIDY_STATUS'),
  }] : []), {
    dataIndex: 'regions',
    key: 'predictedStatus',
    title: Language.get('sample', 'PREDICTED_STATUS'),
    render: (statsRegions) => {
      if (biomaterial['biomaterial_designation']) {
        return biomaterial['gene_regions'].map(({ gene_region_name: name, gene_region_gene_type: type }) => (
          <div key={name}>{name}: {Language.get('gene', type)}</div>
        ));
      }
      return Array.isArray(statsRegions) ? statsRegions.map(({ name, predictedStatus }) => (
        <div key={name}>{name}: {predictedStatus ? Language.get('gene', predictedStatus) : null}</div>
      )) : null;
    },
  }, {
    dataIndex: 'status',
    key: 'status',
    title: Language.get('sample', 'OVERALL_RESULT'),
  }];

  return <Table columns={columns} dataSource={dataSource} pagination={false} />;
};
