// Importing react libs
import React, { Fragment } from 'react';

// Importing antd libs
import { Col, Row } from 'antd';
import 'antd/es/row/style/css';
import 'antd/es/col/style/css';

// Importing Helix hooks
import useLanguage from 'helix-hooks/language';

// Importing component style
import './description.scss';

// Exporting component
export const Description = (props) => {
  const {
    analysisExternalId,
    caseId,
    centre,
    consultant,
    regions,
  } = props;
  const Language = useLanguage();

  return (
    <Fragment>
      <Row gutter={[8, 4]}>
        <Col span={6}><b>{Language.get('case', 'CASE_ID')}:</b> {caseId}</Col>
        <Col span={6}><b>{Language.get('case', 'CONSULTANT')}:</b> {consultant}</Col>
      </Row>
      <Row gutter={[8, 4]}>
        <Col span={6}><b>{Language.get('case', 'ANALYSIS_ID')}:</b> {analysisExternalId}</Col>
        <Col span={6}><b>{Language.get('case', 'REFERRING_CENTRE')}:</b> {centre}</Col>
      </Row>
      <br />
      <Row className='description'>
        <Col span={24} >
          {regions.map((region, index) => {
            const regionEnd = region.start_at + region.length;
            const location = `${region.chromosome}:${region.start_at}-${regionEnd}`;
            return (
              <div key={region.name}>
                <Row>
                  <Col span={6}>
                    <p><b>{`${Language.get('case', 'REGION')} ${index + 1}`}:</b> {region.name}</p>
                  </Col>
                  <Col span={6}>
                    <p><b>{`${Language.get('case', 'LOCATION')} ${index + 1}`}:</b> {location}</p>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
    </Fragment>
  );
};
